html,
body {
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
}

.App {
    text-align: center;
    height: 100%;
    margin-top: 140px;
}

body {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.spacer {
    flex: 1 1 0;
}