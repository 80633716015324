/* Color palette */

:root {
    --pale-grey-background: #f6f8fb;
    --pale-lilac-widget-background: #fbfbff;
    --tangerine-interactive: #ff9500;
    --off-black: #212121;
    --dark-grey-menu-background: #2d3033;
    --azure: #04a0fd;
}

/* Text styles */

.WidgetTitle {
    /*font-family: SFProText;*/
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    color: var(--off-black);
}

body {
    /*font-family: SFProText;*/
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #9fa3a7;
}

.TabSelected {
    /*font-family: SFProText;*/
    font-size: 10px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    text-align: center;
    color: #ff9500;
    color: var(--tangerine-interactive);
}

.TabDeselected {
    /*font-family: SFProText;*/
    font-size: 10px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.4px;
    text-align: center;
    color: #ff9500;
    color: var(--tangerine-interactive);
}

.BodyEmphasis {
    /*font-family: SFProText;*/
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    color: var(--off-black);
}

.MenuSelected {
    /*font-family: SFProText;*/
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.MenuDeselected {
    /*font-family: SFProText;*/
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.5);
}
