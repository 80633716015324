.RouteReport {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.RouteReport .route-info {
    position: absolute;
    top: 18px;
    left: 14px;

    width: 400px;
    
    z-index: 1006;
}

.RouteReport .location {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 0 1 10px;
    align-items: center;
}

.RouteReport .location-card {
    width: 100%;
    min-height: 35px;
    background-color: #696969
}

.RouteReport .location-card-content {
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px 6px 10px;
}

.RouteReport .depart {
    width: 100%;
    margin: 10px 10px 0px 25px;
}

.RouteReport .route-option-info {
    position: absolute;
    left: 14px;
    bottom: 18px;
    
    width: 400px;
    
    z-index: 1006;
}

.RouteReport .route-option-info-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.RouteReport .route-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}

.RouteReport .route-details-card {
    width: 100%;
    min-height: 100px; 
    background-color: #696969;
}

.RouteReport .route-weather-flags {
    width: 100%;
    margin-top: 15px;
}

.RouteReport .select-route {
    width: 100%;
    font-size: 1.4em;
    margin: 15px 0px 15px 0px;
}

.RouteReport .select-route-button {
    height: 70px;
    width: 100%;
}

.RouteReport .card-title {
    color: #b8b8b8;
    font-size: 0.9em;
}

.RouteReport .card-content {
    font-size: 1.8em;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px 16px;
}

.RouteReport .route-navigation-notification {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.RouteReport .route-legend {
    position: absolute;
    backdrop-filter: blur(5px);
    z-index: 1006;
}

.RouteReport .selection-route-legend {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 14px;
}

.RouteReport .navigation-route-legend {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 14px;
}

.RouteReport .collapsed-route-legend {
    top: auto;
    left: auto;
    bottom: 20px;
    right: 14px;
}

.RouteReport .road-risk-legend-rating {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1 0px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 600px){

    [aria-roledescription=map] {
        zoomControl: false;
        scaleControl: true;
    }

    .RouteReport .route-info {
        position: absolute;
        top: 18px;
        left: 14px;
        right: 14px;
    
        width: auto;

        z-index: 1006;
    }

    .RouteReport .route-option-info {
        position: absolute;
        left: 14px;
        right: 14px;
        bottom: 18px;

        width: auto;

        z-index: 1006;
    }

    .RouteReport .route-details-card {
        min-height: 70px;
    }

    .RouteReport .card-title {
        font-size: 0.8em;
        padding: 10px 16px 10px;
    }
    
    .RouteReport .card-content {
        font-size: 1.5em;
        padding: 0px 16px 10px;
    }

    .RouteReport .select-route-button {
        height: 50px;
    }
    
    .RouteReport .selection-route-legend {
        top: 170px;
        left: 14px;   
        right: auto;
        bottom: auto;
    }

    .RouteReport .navigation-route-legend {
        top: 90px;
        left: 14px;   
        right: auto;
        bottom: auto;     
    }

    .RouteReport .collapsed-route-legend {
        top: 14px;
        left: 14px;   
        right: auto;
        bottom: auto;     
    }
}