/*
 * Prevent trackpads from triggering the move back/forward a page when scrolling left and right in the datagrid
 */
.MuiDataGrid-virtualScroller {
  overscroll-behavior-x: contain;
}

.vehicleCellDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  align-content: center;
  margin: 5px 0 5px;
  width: 100%;
  height: 100%;
}

.vehicleTagDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehiclesTable .MuiDataGrid-viewport,
.vehiclesTable .MuiDataGrid-row,
.vehiclesTable .MuiDataGrid-renderingZone,
.vehiclesTable .MuiDataGrid-cell {
  max-height: fit-content !important;
}

.dashboard-alerts-legend {
  min-width: 400px;
  flex-direction: column;

  box-sizing: border-box;
  padding: 14px 9px;

  border-radius: 11px;
}

.dashboard-alerts-legend header {
  display: none;
}

.dashboard-alerts-legend-body {
  max-height: 150px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-y: scroll;
}

.dashboard-alerts-legend .dashboard-alert-column {
  min-width: calc(100% / 3);
}

.dashboard-alerts-legend .dashboard-alert {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-left: 12px;

  margin-top: 10px;
  margin-bottom: 7px;

  align-items: center;
}

.dashboard-alerts-legend .dashboard-alert > div:last-child {
  margin-left: 21px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);
}

.DashboardAlertDetailCards {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  top: 55px;
  right: 12px;
  z-index: 1006;
  box-sizing: border-box;
}

.DashboardAlertDetailsComponent {
  width: 450px;

  box-sizing: border-box;

  border-radius: 11px;
  background-color: rgb(44, 52, 59);
}

.DashboardAlertDetailsComponent-tabbar {
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: space-between;
}

.DashboardAlertDetailsComponent-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DashboardAlertDetailsComponent-body {
  padding-top: 8px;
}

.DashboardAlertDetailsComponent .active-alert-type {
  flex: 1 1 0;

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(235, 238, 239);
}

.DashboardAlertDetailsComponent .active-alert-issued {
  margin-top: 4px;
  margin-left: 8px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);
}

.DashboardAlertDetailsComponent .active-alert-full-description {
  margin-top: 8px;
  margin-bottom: 8px;
  column-count: 1;
}

.DashboardAlertDetailsComponent .active-alert-full-description ul {
  list-style-type: none;
  padding-inline-start: 8px;
  margin: 0px;
}

.DashboardAlertDetailsComponent .active-alert-full-description li > ul {
  list-style-type: circle;
  padding-inline-start: 20px;
}

.DashboardAlertDetailsComponent-body {
  max-height: 40vh;
  overflow-y: auto;
}

.DashboardAlertDetailsComponent.collapsed .DashboardAlertDetailsComponent-body {
  display: none;
}

.impact-tile-legend .impact-legend {
  position: unset !important;
  left: unset !important;
  top: unset !important;
  margin-bottom: unset !important;
}

.EventDescriptionComponent-Container div {
  top: 55px;
}

.road-status-legend {
  z-index: 1002;
  margin: 0px 25px 0px 25px;
}

.road-status-legend-row {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
}

.road-status-legend-item {
  box-sizing: border-box;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storm-report-legend {
  min-width: 400px;
  flex-direction: column;

  box-sizing: border-box;
  padding: 14px 9px;

  border-radius: 11px;
}

.storm-report-legend-body {
  max-height: 150px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-y: auto;
}

.storm-report-legend .storm-report-column {
  min-width: calc(100% / 3);
}

.storm-report-legend .storm-report {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-left: 12px;

  margin-top: 10px;
  margin-bottom: 7px;

  align-items: center;
}

.storm-report-legend .storm-report > div:last-child {
  margin-left: 21px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);
}

.DashboardTabs .Mui-disabled {
  opacity: 1;
  color: rgb(126, 135, 140);
}

.wind-gust-legend .impact-legend {
  position: unset !important;
  left: unset !important;
  top: unset !important;
  margin-bottom: unset !important;
}

.Dashboard .traffic-legend .traffic-legend-header {
  display: none;
}
