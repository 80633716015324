.ClientRadarPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ClientRadarPage .radar-top-left {
  position: absolute;
  top: 9px;
  left: 14px;

  z-index: 1006;
}

.ClientRadarPage .radar-legend {
  margin-top: 12px;
  border-radius: 11px;
  padding: 12px;
}

.ClientRadarPage .legend-label {
  margin: 12px 0 8px 8px;
  font-weight: 600;
}

.ClientRadarPage .legend-label:first-of-type {
  margin-top: 0;
}

.ClientRadarPage .maps {
  display: flex;
  flex-direction: row;

  position: relative;

  width: 100%;
  height: 100%;
}

.ClientRadarPage .single-map {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.ClientRadarPage .single-map .title {
  opacity: 0.87;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(33, 33, 33);
  color: var(--off-black);
}

.ClientRadarPage .single-map .map {
  flex: 1 1 0;
  width: 100%;
  height: 100%;
}

.ClientRadarPage .map img.static-map {
  width: 100%;
}

.ClientRadarPage .map .leaflet-container {
  width: 100%;
  height: 687px;
  /*width: 100px;*/
  /*height: 100px;*/
}

.leaflet-fade-anim .leaflet-popup,
.leaflet-zoom-anim .leaflet-zoom-animated,
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-tile {
  transition: none !important;
}

.MapControlsComponent {
  position: absolute;
  left: 14px;
  bottom: 18px;

  display: flex;
  flex-direction: column;

  z-index: 1006;

  border-radius: 11px;
}

.HorizontalMapControlsComponent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
  padding: 0px 15px;

  z-index: 1006;

  border-radius: 11px;
}

.MapControlsComponent .map-timestamp {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 38px;

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: rgb(255, 255, 255);
}

.HorizontalMapControlsComponent .map-timestamp {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 28px;

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: rgb(255, 255, 255);
}

.MapControlsComponent .map-buttons,
.MapControlsComponent .map-timing,
.HorizontalMapControlsComponent .map-buttons,
.HorizontalMapControlsComponent .map-timing {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.MapControlsComponent .map-buttons {
  height: 46px;
  margin: 0 17px;
}

.MapControlsComponent .map-timing {
  height: 38px;
  margin-left: 5px;
}

.time-control-button {
  border-style: none;
  background: transparent;
  cursor: pointer;

  flex: 1 1 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MapControlsComponent .map-timing .time-control-button {
  border-style: none;
  background: transparent;

  display: flex;

  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 50px;
}

.cycle-speed-increment-button {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #04a0fd;
  text-decoration: none;
}

.MapControlsComponent .map-timing .slider-container {
  width: 200px;

  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HorizontalMapControlsComponent .map-timing .slider-container {
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 480px) {}

@media only screen and (max-width: 480px) {
  .ClientRadarPage {
    display: flex;
    margin-top: 64px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    margin-bottom: 62px;
    box-sizing: border-box;
    width: 100vw;
    overflow-x: hidden;
    height: calc(100vh - 64px - 62px);
    padding: 0;
  }

  .ClientRadarPage .maps {
    top: 64px;
    bottom: 62px;
    margin: 0;
    position: fixed;
    z-index: 1005;
  }

  .ClientRadarPage .radar-legend,
  .ClientRadarPage .radar-legend img {
    max-width: 95vw;
  }

  .ClientRadarPage .maps,
  .ClientRadarPage .single-map,
  .ClientRadarPage .map,
  .ClientRadarPage .map .leaflet-container {
    width: 100vw;
    height: calc(100vh - 126px);
  }

  .ClientRadarPage .map-controls {
    left: 5vw;
    width: 90vw;
  }

  .ClientRadarPage .map-controls>* {
    width: auto;
    flex: 1 1 0;
  }

  .ClientRadarPage .map-controls .slider-container {
    display: none;
  }
}