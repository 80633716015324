.route-tab-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.route-header {
  position: absolute;
  top: 9px;
  left: 14px;

  width: 500px;

  z-index: 1002;

  padding: 15px;
}

.route-legend {
  position: absolute;
  bottom: 30px;
  left: 14px;

  z-index: 1002;

  display: flex;
  flex-direction: row;

  border-radius: 4px;
  border: 1px solid rgb(46, 52, 56);
}

.route-legend>div {
  min-height: 23px;
}

.waypoint-entry {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.waypoint-entry-container {
  width: 100%;
}

.location-textfield {
  display: flex;
  flex-direction: row;
}

.textfield-buttons {
  width: 50px;
  height: 100%;
}

.route-reverse-button {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-waypoint-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* dark mode calendar picker */
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}