.LoginForm {
    z-index: 1101;
    background: #121212;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginForm-wrap {
    text-align: center;
}

.LoginForm-logo {
    height: 70px;
    display: block;
    margin: 0 auto 12px;
}

input.LoginForm-textfield {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #999;
    display: block;
    min-width: 280px;
    margin: 12px 0;

    font-weight: 200;
    font-size: 1.2em;
    text-shadow: none;

    box-sizing: border-box;
}

.LoginForm-textfield::-webkit-input-placeholder {
    font-weight: 200;
    text-shadow: none;
    color: #aaa;
}
