.timeline-tooltip {
  position: fixed;
  text-align: left;
  width: auto;
  border-radius: 3px;
  padding: 8px;
  background: #6a6a6a;
  color: white;
  pointer-events: none;
}

.timeline-tooltip .time {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.timeline-tooltip .impact {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.timeline-tooltip .alert-description {
  max-width: 400px;
}
