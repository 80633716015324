.ClientImpactPage {
  height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
}

.ClientImpactPage .maps {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ClientImpactPage .DynamicImpactMap {
  box-sizing: border-box;
}

.ClientImpactPage .map-container,
.ClientImpactPage .static-map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.ClientImpactPage .static-map-container {
  display: flex;
  background: white;
  align-items: center;
  align-content: center;
}

.ClientImpactPage .static-map {
  margin-left: 274px;
  width: calc(100% - 274px);
  max-height: 100%;
  object-fit: contain;
}

.ImpactMapTopLeftContainer {
  position: absolute;
  top: 80px;
  left: 14px;

  z-index: 1005;
}

.ModelRunComponent {
  border-radius: 11px;

  width: 280px;
  box-sizing: border-box;

  padding: 13px 0;
  margin-top: 3px;
}

.ModelRunComponent .header {
  margin-bottom: 4px;

  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(145, 145, 145);
}

.ModelRunComponent .body {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(255, 255, 255);
}

.ShowLayersComponent {
  border-radius: 11px;

  width: 280px;
  box-sizing: border-box;

  margin-top: 3px;
}

.ShowLayersComponent .switch {
  padding: 0 40px 0 0;

  color: rgb(255, 255, 255);
}

.MapOptions {
  width: 280px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  border-radius: 11px;
}

.MapOptions .expand-button {
  display: none;
}

.MapOptions .title-label {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: rgb(235, 238, 239);

  margin-left: 19px;
  margin-top: 18px;

  flex: 1 1 0;
}

.MapOptions .collapse-button,
.MapOptions .expand-button {
  border-style: none;
  border-radius: 4px;
  background-color: rgb(27, 32, 43);
  cursor: pointer;
  padding: 4px 11px;
  margin: 0 0 18px 8px;

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--azure);
}

.MapOptions .map-options-row {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
  margin-top: 16px;
}

.MapOptions .map-options-row .map-option {
  margin-right: 22px;
  margin-bottom: 16px;
}

.MapOptions .map-option .header {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);

  margin-left: 4px;
  margin-bottom: 6px;
}

.MapOptions.collapsed {
  width: auto;
  height: auto;
  display: inline-block;
}

.MapOptions.collapsed .map-options-row,
.MapOptions.collapsed .collapse-button {
  display: none;
}

.MapOptions.collapsed .expand-button {
  display: block;

  margin: 12px 24px;
}

.__react_component_tooltip {
  max-width: 250px;
}

.impact-legend {
  position: absolute;
  left: 14px;
  top: 9px;
  margin-bottom: 8px;
  z-index: 1006;
}

.impact-legend-numbers {
  height: 23px;

  display: flex;
  flex-direction: row;
}

.impact-legend-rating:first-of-type {
  flex: 0 0 120px;
  border-radius: 4px 0 0 4px;
}

.impact-legend-rating {
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.impact-legend-rating:last-of-type {
  border-radius: 0 4px 4px 0;
}

.impact-legend-words {
  margin-top: 2px;
  height: 19px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 4px;

  text-align: center;
}

.legend-word-danger {
  flex: 0 0 120px;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(237, 237, 237);
}

.legend-word-generic {
  flex: 2 1 0;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(183, 183, 183);
}

.legend-word-generic.small {
  flex-grow: 1;
}

.LocationSelectionComponent-Container {
  width: auto;
  position: absolute;
  top: 55px;
  right: 10px;
  z-index: 1006;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.LocationSelectionComponent {
  width: 320px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  height: 37px;

  flex-basis: 100%;
  /* wrapping of anything else in this flex */
}

.LocationSelectionComponent .search-box {
  position: relative;
  width: 320px;
  height: 38px;
  border-radius: 6px;
}

.LocationSelectionComponent .saved-locations-bubble {
  background-color: rgb(44, 52, 59);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px;
  margin-top: 2px;
  box-sizing: border-box;
}

.LocationSelectionComponent .search-box .search-box-icon {
  position: absolute;
  top: 6px;
  right: 12px;
}

.LocationSelectionComponent .search-box .search-box-input {
  background: transparent;
  border-style: none;

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);

  position: absolute;
  box-sizing: border-box;
  /*width: 268px;*/
  right: 0;
  /*height: 20px;*/
  /*top: 8px;*/
}

.LocationSelectionComponent .saved-locations-label {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  color: rgb(126, 135, 140);

  margin: 0 20px;
}

.LayerDescriptionComponent-Container {
  width: auto;
  position: absolute;
  top: 95px;
  right: 10px;
  z-index: 1005;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.LayerDescriptionComponent {
  width: 300px;
}

.layer-description-header {
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
}

.layer-description-text {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.MapCalloutView {
  position: absolute;

  left: 50%;
  top: 50%;

  z-index: 1004;
}

.MapCalloutView .container {
  min-width: 100px;
  max-width: 400px;
  width: max-content;
  position: absolute;

  transform: translateX(-50%) translateY(-100%);
}

.MapCalloutView.loading .container {
  width: 100px;
}

.MapCalloutView .close-button {
  border-style: none;
  background: transparent;

  margin: 8px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MapCalloutView .body {
  border-radius: 16px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MapCalloutView .index-name {
  margin-top: 9px;
}

.MapCalloutView .zip,
.MapCalloutView .index-name {
  margin-left: 12px;
  margin-right: 12px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);
}

.MapCalloutView .city-name,
.MapCalloutView .index-value {
  margin-left: 12px;
  margin-right: 12px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: rgb(235, 238, 239);
}

.MapCalloutView.animated .index-value,
.MapCalloutView.animated .index-name {
  display: none;
}

.MapCalloutView.loading .zip,
.MapCalloutView.loading .city-name,
.MapCalloutView.loading .index-name,
.MapCalloutView.loading .index-value,
.MapCalloutView.loading .location-button {
  display: none;
}

.MapCalloutView.loading .loading-view img {
  width: 32px;
}

.MapCalloutView .loading-view {
  display: none;

  align-self: center;
  margin: 12px 24px 24px 24px;
}

.MapCalloutView.loading .loading-view {
  display: block;
}

.MapCalloutView .location-button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(27, 32, 43);
  cursor: pointer;
  padding: 4px 11px;
  margin: 9px 12px 12px 12px;
}

.MapCalloutView .location-button span {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--azure);
}

.MapCalloutView .location-button img {
  margin-right: 11px;
}

.MapCalloutView .arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  margin: 0 auto;
}

.TimeSeriesGraphsContainer {
  position: absolute;
  bottom: 0;
  right: 10px;
  left: 308px;

  display: flex;
  flex-direction: column-reverse;

  z-index: 1005;
}

.TimeSeriesGraphComponent {
  position: "absolute";
  bottom: 24;
  left: 12;
  right: 64;
}

/*
 * Graph Widget
 */

.ClientWidgetContainer {
  margin: 25px;

  height: 325px;

  border-radius: 4px;
  background-color: rgb(11, 19, 23);
  box-shadow: 0 0 21px 0 rgba(173, 182, 217, 0.17);
}

.ClientWidgetContainer-header {
  background-color: rgb(11, 19, 23);
  box-shadow: 0 -1px 0 0 rgba(173, 182, 217, 0.17);
  height: 82px;
  border-bottom: 1px solid rgba(173, 182, 217, 0.17);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.ClientWidgetContainer-title {
  opacity: 0.87;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(235, 238, 239);

  margin: 0 10px 0 45px;
  flex-shrink: 0;
}

.ClientWidgetContainer-body {}

.ClientWidgetContainer .ClientWidgetContainer-body .js-plotly-plot {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.GraphWidgetLegendItem {
  margin: 7px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.GraphWidgetLegendItem .circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 12px;
}

.GraphWidgetLegendItem .label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
}

@media only screen and (min-width: 480px) {
  .ClientWidgetContainer-header-link {
    background: transparent;

    margin: 0 16px;
    padding: 8px 20px;
    text-decoration: none;
    cursor: pointer;

    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #04a0fd;

    border-radius: 3px;
    border: 1px solid #04a0fd;
  }

  .ClientWidgetContainer-header-link:last-of-type {
    margin-right: 45px;
  }

  .ClientWidgetContainer-header-link.active {
    color: white;
    background-color: #04a0fd;
  }

  .desktop-legend {
    display: block;
  }

  .mobile-legend {
    display: none;
  }
}