.ClientTrafficPage {
    height: 100%;
    position: relative;
}

.ClientAlertsPage .traffic-legend {
    position: absolute;
    top: 9px;
    left: 14px;

    z-index: 1006;

    flex-direction: column;

    box-sizing: border-box;
    padding: 14px 21px;

    border-radius: 11px;
}

.ClientAlertsPage .traffic-legend .traffic-legend-header {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.07px;
    color: rgb(235, 238, 239);
}

.ClientAlertsPage .traffic-legend .traffic-legend-select-wrapper {
    padding: 15px 0px !important;
    flex-direction: column !important;
}

.ClientAlertsPage .traffic-legend .traffic-legend-select-wrapper p {
    margin: 10px 0px 0px 0px !important;
}

.ClientAlertsPage .traffic-legend .traffic-legend-image {
    padding: unset !important;
}

.road-status-legend {
    z-index: 1002;
    margin: 0px 25px 0px 0px;
    min-width: 500px;
}

.road-status-legend-row {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
}

.road-status-legend-item {
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.07px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}