.ClientHistoricalAccuracyPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ClientHistoricalAccuracyPage .maps {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ClientHistoricalAccuracyPage .historical-accuracy-event-selector {
  position: absolute;
  top: 9px;
  left: 14px;

  width: 350px;

  z-index: 1006;
}

.ClientHistoricalAccuracyPage .MapCalloutView {
  z-index: 10006;
}

.EventSelectionComponent {
  display: flex;
  flex-direction: column;
}

.event-selection-header,
.date-of-forecast,
.day-range-labels,
.event-description-header {
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

.event-selection-title,
.event-description-title {
  font-size: 18px;
}

.EventSelectionComponent .select-title {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);

  margin-top: 10px;
  margin-left: 4px;
  margin-bottom: 6px;
}

.ClientHistoricalAccuracyPage .event-description-component {
  position: absolute;
  left: 14px;
  bottom: 14px;
  top: 450px;
  right: 70%;

  z-index: 1006;
}

.ClientHistoricalAccuracyPage .event-description-component-collapsed {
  position: absolute;
  left: 14px;
  bottom: 14px;
  right: 70%;

  z-index: 1006;
}

.ClientHistoricalAccuracyPage .event-description-text {
  margin-top: 10px;
  max-height: calc(100% - 60px);
  overflow: auto;
}

.ClientHistoricalAccuracyPage .impact-legend {
  position: absolute;
  top: auto;
  left: auto;
  bottom: 8px;
  right: 55px;

  z-index: 1006;
}

.historical-accuracy-marker-content {
  display: flex;
  flex-direction: row;
  min-width: 300px;
  padding: 8px 12px 8px 12px;
  font-size: 12px;
}

.historical-accuracy-marker-content-title {
  font-weight: bold;
  color: #fff;
  min-width: 80px;
}

.historical-accuracy-marker-content-text {
  color: #ccc;
  padding-left: 10px;
}

.historical-accuracy-marker-content-text img {
  width: 12px;
}

.MapCalloutView .route-container {
  width: 500px;
  position: absolute;

  transform: translateX(-50%) translateY(-100%);
}

.MapCalloutView.loading .route-container {
  width: 100px;
}

.historical-accuracy-route-content {
  display: flex;
  flex-direction: row;
  min-width: 300px;
  padding: 8px 12px 8px 12px;
  font-size: 12px;
}

.historical-accuracy-route-content-title {
  font-weight: bold;
  color: #fff;
  min-width: 120px;
}

.historical-accuracy-route-content-text {
  color: #ccc;
  padding-left: 10px;
}