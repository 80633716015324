.ClientAlertsPage {
  height: 100%;
  position: relative;
}

.ClientAlertsPage .maps {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  position: relative;
}

.ClientAlertsPage .single-map {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.ClientAlertsPage .single-map .title {
  opacity: 0.87;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(33, 33, 33);
  color: var(--off-black);
}

.ClientAlertsPage .single-map .map {
  flex: 1 1 0;
}

.ClientAlertsPage .map .leaflet-container {
  width: 100%;
  height: 687px;
  /*width: 100px;*/
  /*height: 100px;*/
}

.ClientAlertsPage .alerts-legend {
  position: absolute;
  top: 9px;
  left: 14px;

  z-index: 1006;

  width: 325px;
  flex-direction: column;

  box-sizing: border-box;
  padding: 14px 21px;

  border-radius: 11px;
}

.ClientAlertsPage .alerts-legend header {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: rgb(235, 238, 239);
}

.ClientAlertsPage .alerts-legend-body {
  max-height: 60vh;
  overflow-y: auto;
  padding-top: 6px;
}

.ClientAlertsPage .alerts-legend .alert {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-left: 12px;

  margin-top: 10px;
  margin-bottom: 7px;

  align-items: center;
}

.ClientAlertsPage .alerts-legend .alert>div:last-child {
  margin-left: 31px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);
}

.AlertDetailCards {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  bottom: 31px;
  left: 19px;
  right: 77px;
  z-index: 1004;
  box-sizing: border-box;
}

.AlertDetailsComponent {
  /* TODO: removed because seems unnecessary */
  /* margin-top: 20px; */

  padding: 2px;

  box-sizing: border-box;

  border-radius: 11px;
  background-color: rgb(44, 52, 59);
}

.AlertDetailsComponent-tabbar {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

.AlertDetailsComponent-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active-alert-type {
  flex: 1 1 0;

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(235, 238, 239);
}

.active-alert-issued {
  margin-top: 4px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: rgb(126, 135, 140);
}

.active-alert-full-description {
  margin-top: 23px;
  margin-bottom: 12px;

  column-count: 2;
  column-gap: 90px;
}

.AlertDetailsComponent-body {
  max-height: 20vh;
  overflow-y: auto;
}

.AlertDetailsComponent.collapsed .AlertDetailsComponent-body {
  display: none;
}