.TimeSeriesGraphComponent .ClientWidgetContainer {
    border-radius: 11px;
    margin: 0;

    overflow: hidden;
    transition: height 0.2s ease-out 0ms;
}

.TimeSeriesGraphComponent .ClientWidgetContainer-header {
    box-shadow: none;
    height: 50px;
    flex-wrap: wrap;
    background: transparent;
}

.TimeSeriesTabs .Mui-disabled {
    opacity: 1;
    color: rgb(126, 135, 140);
}

.TimeSeriesGraphComponent .ClientWidgetContainer-title {
    font-size: 16px;
    font-weight: 600;
    margin: 12px 0 0 25px;
    width: 100%;
}

.TimeSeriesGraphComponent .collapsed-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TimeSeriesGraphComponent .graph-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 25px;
}

.TimeSeriesGraphComponent .header-title-graph-type, .TimeSeriesGraphComponent .header-title-graph-location {
    margin: 0 0 3px 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
    color: rgb(235, 238, 239);
}

.TimeSeriesGraphComponent .header-title-for {
    margin: 0 6px 1px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
    color: rgb(126, 135, 140);
}

.TimeSeriesGraphComponent .ClientWidgetContainer-body {
    background-color: rgb(67, 72, 75);
    border-radius: 0 0 11px 0;
    padding: 0 11px 0 0;
    height: 275px;
}

.ClientWidgetContainer-body {
    position: relative;
}

.TimeSeriesGraphComponent .slider {
    width: 150px;
}

.TimeSeriesGraphComponent .zoom-container {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
}