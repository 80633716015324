.GovernmentalAlert {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.GovernmentalAlert .alert-info {
    position: absolute;
    bottom: 32px;
    left: 14px;

    width: 400px;

    z-index: 1006;
}

.GovernmentalAlert .active-alert-full-description {
    height: 200px;
    margin-top: 8px;
    margin-bottom: 8px;
    column-count: unset;
    white-space: pre-wrap;
    overflow: scroll;
}

.GovernmentalAlert .active-alert-full-description ul {
    list-style-type: none;
    padding-inline-start: 8px;
    margin: 0px;
}

.GovernmentalAlert .active-alert-full-description li>ul {
    list-style-type: circle;
    padding-inline-start: 20px;
}

@media only screen and (max-width: 600px) {

    [aria-roledescription=map] {
        zoomControl: false;
        scaleControl: true;
    }

    .GovernmentalAlert .alert-info {
        position: absolute;
        bottom: 32px;
        left: 14px;
        right: 14px;

        width: auto;

        z-index: 1006;
    }
}